import React from "react";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';
import Layout from "../../components/layout";
import Title from '../../components/title';

export default ({ location, data }) => (
    
    <Layout
      path={location.pathname}>

      <Helmet>
        <title>Blog</title>
      </Helmet>

      <Title
        subtitle="Blog"
        title="Helpful guides and tips for freelancers."
        width="max-w-xs md:max-w-md lg:max-w-xl" />

      <div className="lg:container max-w-6xl mx-auto mt-20">

        <div className="grid grid-cols-3 gap-4">

        { data.prismic.allBlogs.edges.map(item => (
          <a className="bg-box_dark" href={`/blog/${item.node._meta.uid}`}>
            
            <figure className="h-64 bg-root">
              <img src={item.node.image.url} class="object-left-top	object-cover w-full h-full"/>
            </figure>

            <h2 className="text-xl font-medium p-6">{RichText.render(item.node.title)}</h2>

          </a>
        ))}

        </div>

      </div>

    </Layout>
)

export const query = graphql`
{
  prismic {
    allBlogs {
      edges {
        node {
          title
          text
          image
          _meta {
            uid
            type
          }
        }
      }
    }
  }
}
`